import {
  CLEAR_ENVIRONMENT,
  SELECT_ENVIRONMENT
} from "../actions/environmentActions";
import {
  ServiceEnvironment,
  clearServiceEnvironment,
  getServiceEnvironment,
  persistServiceEnvironment
} from "../utils/ServiceEnvironment";

export interface EnvironmentState {
  environment?: ServiceEnvironment;
}

const defaultState = {
  environment: getServiceEnvironment()
} as EnvironmentState;

const environmentReducer = (
  state = defaultState,
  action: { type: string; environment: ServiceEnvironment }
) => {
  switch (action.type) {
    case SELECT_ENVIRONMENT:
      persistServiceEnvironment(action.environment);
      return {
        ...state,
        environment: action.environment
      };
    case CLEAR_ENVIRONMENT:
      clearServiceEnvironment();
      return {
        ...state,
        environment: null
      };
    default:
      return state;
  }
};

export default environmentReducer;
