import { DataSource } from "@saleor/entity/DataSource";
import { generateUrl, getToken, routes } from "pact.drural.app-skeleton";
import { openNotification } from "pact.drural.notifications";
import { generateQuery, SearchRequest } from "pact.drural.ui";
import { validateInput } from "../validation/InputValidator";

export const SET_DATASOURCE_SHORT_LIST = "SET_DATASOURCE_SHORT_LIST";
export const SET_CURRENT_DATASOURCE = "SET_CURRENT_DATASOURCE";
export const SET_ITEM_DATA = "SET_ITEM_DATA";
export const START_LOAD_ITEM_DATA = "START_LOAD_CHART_DATA";
export const ITEM_DATA_VALIDATION_ERRORS = "ITEM_DATA_VALIDATION_ERRORS";

const setCurrentDataSource = (dataSource: DataSource) => ({
  type: SET_CURRENT_DATASOURCE,
  dataSource
});

export const loadDataSource = (name: string) => dispatch => {
  return getToken()
    .then(token => {
      return fetch(
        generateUrl(routes.data.v1.setByName, { dataSetName: name }),
        {
          method: "GET",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token
          }
        }
      );
    })
    .then(response => response.json())
    .then(json => {
      dispatch(setCurrentDataSource(json));
    });
};

const setDataSourceShortList = (dataSourceShortList: any) => ({
  type: SET_DATASOURCE_SHORT_LIST,
  dataSourceShortList
});

export const searchDataSourceShortList =
  (request: SearchRequest) => dispatch => {
    return getToken()
      .then(token => {
        return fetch(
          generateUrl(routes.data.v1.dataSets) + generateQuery(request),
          {
            method: "GET",
            mode: "cors",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token
            }
          }
        );
      })
      .then(response => response.json())
      .then(json => {
        dispatch(setDataSourceShortList(json));
      });
  };

const startLoadItemData = item => ({
  type: START_LOAD_ITEM_DATA,
  item
});

const setItemData = (item, data) => ({
  type: SET_ITEM_DATA,
  item,
  data
});

const setItemValidationErrors = (item, errors) => ({
  type: ITEM_DATA_VALIDATION_ERRORS,
  item,
  errors
});

export const loadItemData = item => dispatch => {
  let validationResult = validateInput(item);
  if (!validationResult.isValid) {
    return dispatch(setItemValidationErrors(item, validationResult.errors));
  }

  dispatch(startLoadItemData(item));

  return getToken()
    .then(token => {
      return fetch(generateUrl(routes.data.v1.query), {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token
        },
        body: JSON.stringify(item)
      });
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      }

      dispatch(openNotification("ERROR_UNKOWN"));
    })
    .then(json => {
      dispatch(setItemData(item, json));
    })
    .catch(() => {
      dispatch(openNotification("ERROR_UNKOWN"));
    });
};
