import { ServiceEnvironment } from "../utils/ServiceEnvironment";

export const SELECT_ENVIRONMENT = "SELECT_ENVIRONMENT";
export const CLEAR_ENVIRONMENT = "CLEAR_ENVIRONMENT";

export const selectEnvironment = (environment: ServiceEnvironment) => ({
  type: SELECT_ENVIRONMENT,
  environment
});

export const clearEnvironment = () => ({
  type: CLEAR_ENVIRONMENT
});
