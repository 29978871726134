export const enum ItemType {
    Bar = 0,
    Line = 1,
    Doughnut = 2,
    Scatter = 3,
    Map = 4,
    Pie = 5,
    Spider = 6,
    Table = 7,
    Description= 8,
    Tile = 9
}