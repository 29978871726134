import { DataSourceFilterType } from "../../entity/DataSourceFilterType";
import { ItemType } from "../../entity/item/ItemType";
import { ValidationSummary } from "../types/ValidationSummary";

export const validateInput = (item:any): ValidationSummary => {
    let validationSummary = [];
    if (item.values.length === 0) {
        validationSummary.push("ERROR_FIELD_SELECTION_MISSING")
    }

    if (item.values.length === 1 && item.type === ItemType.Scatter) {
        validationSummary.push("ERROR_FIELD_SCATTER_SELECTION_MISSING")
    }

    if (item.mappings.find(m => m.columnName === DataSourceFilterType.Region) === undefined) {
        validationSummary.push("ERROR_REGION_MISSING")
    }

    if (item.type !== ItemType.Line && item.filters.find(m => m.columnName === "Perioden") === undefined) {
        validationSummary.push("ERROR_TIME_PERIOD_MISSING")
    }

    if ((item.type !== ItemType.Pie && item.type !== ItemType.Doughnut) && item.filters.find(m => m.columnName === "Leeftijd") === undefined) {
        validationSummary.push("ERROR_AGE_MISSING")
    }

    return {
        errors: validationSummary,
        isValid: validationSummary.length === 0
    };
}