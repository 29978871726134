import {
  generateUrl,
  routes,
  toggleBackdropSpinner,
  getToken
} from "pact.drural.app-skeleton";
import { openNotification } from "pact.drural.notifications";
import { generateQuery } from "pact.drural.ui";

import { loadItemData } from "./dataActions";

export const SET_REPORT = "SET_REPORT";
export const SET_REPORT_NAME = "SET_REPORT_NAME";
export const SET_REPORT_SUMMARY = "SET_REPORT_SUMMARY";
export const SET_REPORT_DESCRIPTION = "SET_REPORT_DESCRIPTION";

export const START_LOAD_REPORT_LIST = "START_LOAD_REPORT_LIST";
export const SET_REPORT_LIST = "SET_REPORT_LIST";
export const SET_REPORT_METADATA = "SET_REPORT_METADATA";

export const ADD_SECTION_DESCRIPTION = "ADD_SECTION_DESCRIPTION";
export const CHANGE_SECTION_DESCRIPTION = "CHANGE_SECTION_DESCRIPTION";
export const DELETE_SECTION_DESCRIPTION = "DELETE_SECTION_DESCRIPTION";

export const setReportMetadata = service => ({
  type: SET_REPORT_METADATA,
  service
});

export const addSectionDescription = sectionId => ({
  type: ADD_SECTION_DESCRIPTION,
  sectionId
});

export const changeSectionDescription = (sectionId, description) => ({
  type: CHANGE_SECTION_DESCRIPTION,
  sectionId,
  description
});

export const deleteSectionDescription = sectionId => ({
  type: DELETE_SECTION_DESCRIPTION,
  sectionId
});

export const setReportName = name => ({
  type: SET_REPORT_NAME,
  name
});

export const setReportSummary = summary => ({
  type: SET_REPORT_SUMMARY,
  summary
});

export const setReportDescription = description => ({
  type: SET_REPORT_DESCRIPTION,
  description
});

const startLoadReportList = () => ({
  type: START_LOAD_REPORT_LIST
});

const setReportList = result => ({
  type: SET_REPORT_LIST,
  result
});

export const loadReportList = request => dispatch => {
  dispatch(startLoadReportList());

  return getToken()
    .then(token => {
      let headers =
        token === null
          ? { "Content-Type": "application/json" }
          : {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token
            };

      return fetch(
        generateUrl(routes.report.v1.list) +
          generateQuery(request) +
          "&csp=" +
          request.csp,
        {
          method: "GET",
          mode: "cors",
          headers: headers
        }
      );
    })
    .then(response => response.json())
    .then(json => {
      dispatch(setReportList(json));
    });
};

const setReport = report => ({
  type: SET_REPORT,
  report: report
});

export const loadReport = id => dispatch => {
  dispatch(toggleBackdropSpinner());

  return getToken()
    .then(token => {
      let headers =
        token === null
          ? { "Content-Type": "application/json" }
          : {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token
            };

      return fetch(generateUrl(routes.report.v1.byId, { reportId: id }), {
        method: "GET",
        mode: "cors",
        headers: headers
      });
    })
    .then(response => response.json())
    .then(json => {
      dispatch(toggleBackdropSpinner());

      dispatch(setReport(json));
      for (let i = 0; i < json.metrics.length; i++) {
        if (!json.metrics[i].createdManually) {
          dispatch(loadItemData(json.metrics[i]));
        }
      }

      for (let i = 0; i < json.sections.length; i++) {
        for (let j = 0; j < json.sections[i].charts.length; j++) {
          dispatch(loadItemData(json.sections[i].charts[j]));
        }
      }
    });
};

export const saveReport =
  (report, environment, type = "create") =>
  dispatch => {
    dispatch(toggleBackdropSpinner());

    return getToken()
      .then(token => {
        return fetch(
          generateUrl(routes.report.v1.create) + "?csp=" + environment,
          {
            method: type === "create" ? "POST" : "PUT",
            mode: "cors",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token
            },
            body: JSON.stringify(report)
          }
        );
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        }

        dispatch(toggleBackdropSpinner());
        dispatch(openNotification("ERROR_UNKOWN"));
      })
      .then(json => {
        window.location.href = "/report?r=" + json.id;
      })
      .catch(error => {
        dispatch(toggleBackdropSpinner());
        dispatch(openNotification("ERROR_UNKOWN"));
      });
  };

export const setReportPermission =
  (reportId, permission, callback) => dispatch => {
    dispatch(toggleBackdropSpinner());

    return getToken()
      .then(token => {
        return fetch(generateUrl(routes.report.v1.setPermission), {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token
          },
          body: JSON.stringify({ reportId, permission })
        });
      })
      .then(response => {
        if (response.ok) {
          dispatch(openNotification("REPORT_PERMISSION_SET"));
        } else {
          dispatch(openNotification("ERROR_UNKOWN"));
        }

        dispatch(toggleBackdropSpinner());
        callback();
      })
      .catch(error => {
        dispatch(toggleBackdropSpinner());
        dispatch(openNotification("ERROR_UNKOWN"));
      });
  };
