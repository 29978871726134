import { combineReducers } from "redux";

import reportReducer from "./reportReducer";
import filterReducer from "./filterReducer";
import dataReducer from "./dataReducer";
import regionReducer from "./regionReducer";
import environmentReducer from "./environmentReducer";

import {
  navigationReducer,
  backdropReducer,
  userReducer,
  authenticationReducer,
  localizationReducer
} from "pact.drural.app-skeleton";
import { notificationReducer } from "pact.drural.notifications";
import { serviceReducer } from "pact.drural.ui";

const rootReducer = combineReducers({
  environment: environmentReducer,
  navigation: navigationReducer,
  notification: notificationReducer,
  backdrop: backdropReducer,
  authentication: authenticationReducer,
  service: serviceReducer,
  report: reportReducer,
  filter: filterReducer,
  user: userReducer,
  data: dataReducer,
  region: regionReducer,
  localization: localizationReducer
});

export default rootReducer;
