import { ItemType } from "../../entity/item/ItemType";

export const deleteMetric = (state, action) => {
    return {
        ...state,
        metrics: state.metrics.filter(t => t.id !== action.metric.id)
    }
}

export const moveMetric = (state, action) => {
    let previousMetricIndex = state.metrics.findIndex(c => c.id === action.targetMetric.id);
    let movedMetricIndex  = state.metrics.findIndex(c => c.id === action.movedMetric.id);

    return { 
        ...state, 
        metrics: swapMetrics(state.metrics, previousMetricIndex, movedMetricIndex)
    }
}

const swapMetrics = (metrics, previousMetricIndex, movedMetricIndex) => {
    const swappedMetrics = [...metrics];
    [swappedMetrics[previousMetricIndex], swappedMetrics[movedMetricIndex]] = [swappedMetrics[movedMetricIndex], swappedMetrics[previousMetricIndex]]

    return swappedMetrics;
}

export const changeMetricTitle = (state, action) => {
    return {
        ...state, 
        metrics: state.metrics.map(
            (metric) => metric.id === action.metric.id ? 
                                        {
                                            ...metric,
                                            title: action.title
                                        }
                                    : metric
        )               
    }
}

export const createMetric = (state, action) => {
    return {
        ...state,
        metrics: [...state.metrics, {
            id: Math.floor(Math.random() * 10000000) + "",
            variant: null,
            title: action.metric.title,
            value: action.metric.value,
            dataSourceId: action.metric.dataSourceId,
            dataSetName: action.metric.dataSourceName,
            icon: action.metric.icon,
            createdManually: action.metric.createdManually,
            type: ItemType.Tile,
            isLoading: false,
            values: [],
            mappings: [],
            filters: [],
            errors: [],
            from: null,
            to: null
        }]
    }
}