import { ITEM_DATA_VALIDATION_ERRORS, SET_ITEM_DATA, START_LOAD_ITEM_DATA } from "../actions/dataActions";
import { 
    ADD_ITEM_FILTER, 
    ADD_ITEM_SOURCE_VARIANT, 
    ADD_ITEM_TARGET, 
    ADD_ITEM_VALUE, 
    ADD_SELECTOR_MAPPING_TO_ITEM,
    DELETE_SELECTOR_MAPPING_FROM_ITEM, 
    SWITCH_SELECTOR_MAPPING_OF_ITEM,
    DELETE_ITEM_VALUE, 
    REMOVE_ITEM_SOURCE_VARIANT,
    DROP_SELECTOR_MAPPINGS_OF_ITEM,
    SET_ITEM_DATE_RANGE
} from "../actions/filterActions";
import { 
    SET_REPORT, 
    SET_REPORT_NAME, 
    SET_REPORT_DESCRIPTION, 
    SET_REPORT_LIST, 
    ADD_SECTION_DESCRIPTION,
    CHANGE_SECTION_DESCRIPTION,
    DELETE_SECTION_DESCRIPTION,
    SET_REPORT_METADATA,
    START_LOAD_REPORT_LIST,
    SET_REPORT_SUMMARY
} from "../actions/reportActions";

import { CHANGE_METRIC_TITLE, CREATE_METRIC, DELETE_METRIC, MOVE_METRIC } from "../actions/metricActions";
import { changeMetricTitle, createMetric, deleteMetric, moveMetric } from './report/metricReducer'
import { 
    addItemFilter, 
    addItemSourceVariant, 
    addItemTarget,
    addItemValue, 
    addSelectorMappingToItem, 
    deleteItemValue, 
    deleteSelectorMappingFromItem, 
    dropSelectorMappingsOfItem, 
    removeItemSourceVariant, 
    setDateRangeOfItem, 
    setItemData, 
    setItemValidationErrors, 
    startLoadItemData, 
    switchSelectorMappingOfItem
} from './report/itemReducer'
import { addSectionDescription, changeChartTitle, changeSectionDescription, createChart, deleteChart, deleteSectionDescription, moveChart } from './report/chartReducer'
import { CHANGE_CHART_TITLE, CREATE_CHART, DELETE_CHART, MOVE_CHART } from "../actions/chartActions";

const defaultState = {
    list: {
        items: [],
        selectedItems: [],
        totalCount: 0,
        pageInfo: { },
        isLoading: false
    },
    name: "",
    id: "",
    summary: "",
    description: "",
    serviceId: "",
    serviceName: "",
    serviceProvider: "",
    metrics: [],
    sections: [],
    status: -1
}

const reportReducer = (state = defaultState, action)=> {
    switch (action.type) {
        case SET_REPORT:
            return {
                ...state,
                name: action.report.name,
                id: action.report.id,
                summary: action.report.summary,
                description: action.report.description,
                metrics: action.report.metrics,
                sections: action.report.sections,
                serviceId: action.report.serviceId,
                serviceName: action.report.serviceName,
                serviceProvider: action.report.serviceProvider,
                status: action.report.status
            }
        case START_LOAD_REPORT_LIST:
            return {
                ...state,
                list: {
                    ...state.list,
                    isLoading: true
                }
            }
        case SET_REPORT_LIST:
            return {
                ...state,
                list: {
                    ...state.list,
                    items: action.result.reports,
                    totalCount: action.result.totalCount,
                    pageInfo: action.result.pageInfo,
                    isLoading: false
                }
            }
        case SET_REPORT_NAME:
            return {
                ...state,
                name: action.name
            }
        case SET_REPORT_DESCRIPTION:
            return {
                ...state,
                description: action.description
            }
        case SET_REPORT_SUMMARY:
            return {
                ...state,
                summary: action.summary
            }
        case SET_REPORT_METADATA:
            return {
                ...state,
                id: Math.floor(Math.random() * 10000000) + "",
                serviceId: action.service.id,
                serviceName: action.service.serviceName,
                serviceProvider: action.service.serviceProvider
            }
        case START_LOAD_ITEM_DATA:
            return startLoadItemData(state, action)
        case SET_ITEM_DATA:
            return setItemData(state, action)
        case CREATE_METRIC:
            return createMetric(state, action)
        case DELETE_METRIC:
            return deleteMetric(state, action)
        case SET_ITEM_DATE_RANGE:
            return setDateRangeOfItem(state, action)
        case ADD_SELECTOR_MAPPING_TO_ITEM:
            return addSelectorMappingToItem(state, action)
        case SWITCH_SELECTOR_MAPPING_OF_ITEM:
            return switchSelectorMappingOfItem(state, action)
        case DELETE_SELECTOR_MAPPING_FROM_ITEM:
            return deleteSelectorMappingFromItem(state, action)
        case DROP_SELECTOR_MAPPINGS_OF_ITEM:
            return dropSelectorMappingsOfItem(state, action)
        case ADD_ITEM_SOURCE_VARIANT:
            return addItemSourceVariant(state, action)
        case REMOVE_ITEM_SOURCE_VARIANT:
            return removeItemSourceVariant(state, action)
        case ADD_ITEM_VALUE:
            return addItemValue(state, action)
        case DELETE_ITEM_VALUE:
            return deleteItemValue(state, action)
        case ADD_ITEM_TARGET:
            return addItemTarget(state, action)
        case ADD_ITEM_FILTER:
            return addItemFilter(state, action)
        case MOVE_METRIC:
            return moveMetric(state, action)
        case MOVE_CHART:
            return moveChart(state, action)
        case CHANGE_METRIC_TITLE:
            return changeMetricTitle(state, action)
        case CHANGE_CHART_TITLE:
            return changeChartTitle(state, action)
        case ITEM_DATA_VALIDATION_ERRORS:
            return setItemValidationErrors(state, action)
        case CREATE_CHART:
            return createChart(state, action)
        case DELETE_CHART:
            return deleteChart(state, action)
        case ADD_SECTION_DESCRIPTION:
            return addSectionDescription(state, action)
        case CHANGE_SECTION_DESCRIPTION:
            return changeSectionDescription(state, action)
        case DELETE_SECTION_DESCRIPTION:
            return deleteSectionDescription(state, action)
        default:
            return state;
    }
}

export default reportReducer;