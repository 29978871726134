export const CREATE_METRIC = "CREATE_METRIC"
export const DELETE_METRIC = "DELETE_METRIC"
export const MOVE_METRIC = "MOVE_METRIC"
export const CHANGE_METRIC_TITLE = "CHANGE_METRIC_TITLE"


export const createMetric = (metric) => ({
    type: CREATE_METRIC,
    metric
})

export const deleteMetric = (metric) => ({
    type: DELETE_METRIC,
    metric
})

export const moveMetric = (movedMetric, targetMetric) => ({
    type: MOVE_METRIC,
    movedMetric,
    targetMetric
})

export const changeMetricTitle = (metric, title) => ({
    type: CHANGE_METRIC_TITLE,
    metric,
    title
})