import { ValidationSummary } from "./types/ValidationSummary";

import { validateInput as validate50090 } from "./validators/50090NEDInputValidator";
import { validateInput as validateWaitingTimes } from "./validators/WaitingTimesInputValidator";
import { validateInput as validateReferralInput } from "./validators/ReferralValidator";
import { validateInput as validateSearchTermsInput } from "./validators/SearchTermsValidator";

export const validateInput = (item: any): ValidationSummary => {
  switch (item.dataSetName) {
    case "50090NED":
      return validate50090(item);
    case "Waiting Times":
      return validateWaitingTimes(item);
    case "Referrals":
      return validateReferralInput(item);
    case "Search Terms":
      return validateSearchTermsInput(item);
  }

  return {
    isValid: false
  } as ValidationSummary;
};
