import { generateUrl, routes } from "pact.drural.app-skeleton";

export const SEARCH_REGION_AUTOCOMPLETE = "SEARCH_REGION_AUTOCOMPLETE";

const searchRegionAutocomplete = regions => ({
  type: SEARCH_REGION_AUTOCOMPLETE,
  regions
});

export const searchRegion = input => dispatch => {
  if (input.length < 3) {
    return dispatch(searchRegionAutocomplete([]));
  }

  return fetch(
    generateUrl(routes.region.v1.search).concat("?input=").concat(input),
    {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json"
      }
    }
  )
    .then(response => response.json())
    .then(json => {
      dispatch(searchRegionAutocomplete(json));
    });
};
