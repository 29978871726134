import { SEARCH_REGION_AUTOCOMPLETE } from "../actions/regionActions";


const defaultState = {
    autocompleteResult: []
}

const regionReducer = (state = defaultState, action)=> {
    switch (action.type) {
        case SEARCH_REGION_AUTOCOMPLETE:
            return {
                ...state,
                autocompleteResult: action.regions.map(region => {
                    return {
                        id: region.id,
                        municipalityName: region.municipalityName,
                        neighborhood: region.neighborhood,
                        label: `${region.municipalityName} (${region.neighborhood})`
                    }   
                })
            }
        default:
            return state;
    }
}

export default regionReducer;