import { 
    SET_CURRENT_DATASOURCE,
    SET_DATASOURCE_SHORT_LIST
} from "../actions/dataActions";

const defaultState = {
    list: {
        items: [],
        totalCount: 0,
        pageInfo: { },
        isLoading: false
    },
    current: null
}

const dataReducer = (state = defaultState, action)=> {
    switch (action.type) {
        case SET_DATASOURCE_SHORT_LIST:
            return {
                ...state,
                list: {
                    ...state.list,
                    items: action.dataSourceShortList.dataSets,
                    totalCount: action.dataSourceShortList.totalCount,
                    pageInfo: action.dataSourceShortList.pageInfo
                }
            }
        case SET_CURRENT_DATASOURCE:
            return {
                ...state,
                current: action.dataSource
            }
        default:
            return state;
    }
}

export default dataReducer;