import { DataSourceFilterType } from "../../entity/DataSourceFilterType";
import { ValidationSummary } from "../types/ValidationSummary";

export const validateInput = (item: any): ValidationSummary => {
    let validationSummary = [];
    if (item.variant === null || item.variant === "" || item.variant === undefined) {
        return {
            errors: ["ERROR_NO_VARIANT_SELECTED"],
            isValid: false
        }
    }

    if (item.variant.name === "WAITING_TIMES_BY_SERVICES")  {
        if (item.mappings.find(m => m.columnName === DataSourceFilterType.Service) === undefined) {
            validationSummary.push("ERROR_NO_SERVICE_SELECTED")
        }
    } else if (item.variant.name === "WAITING_TIMES_BY_CATEGORY") {
        if (item.mappings.find(m => m.columnName === DataSourceFilterType.ServiceCategory) === undefined) {
            validationSummary.push("ERROR_NO_CATEGORY_SELECTED")
        }
    }

    return {
        errors: validationSummary,
        isValid: validationSummary.length === 0
    };
}