export const CREATE_CHART = "CREATE_CHART"
export const DELETE_CHART = "DELETE_CHART"

export const CHANGE_CHART_TITLE = "CHANGE_CHART_TITLE"
export const MOVE_CHART = "MOVE_CHART"

export const createChart = (section, chart) => ({
    type: CREATE_CHART,
    section,
    chart
})

export const deleteChart = (chart) => ({
    type: DELETE_CHART,
    chart
})

export const moveChart = (movedChart, targetChart) => ({
    type: MOVE_CHART,
    movedChart,
    targetChart
})

export const changeChartTitle = (chart, title) => ({
    type: CHANGE_CHART_TITLE,
    chart,
    title
})