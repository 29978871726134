import { getUrlParameter } from "pact.drural.utils";

export const enum ServiceEnvironment {
  Impact = 0,
  Overview = 1,
  Referrals = 2
}

const serviceEnvironmentKey = "serviceEnvironment";

export const clearServiceEnvironment = () => {
  localStorage.removeItem(serviceEnvironmentKey);
};

export const persistServiceEnvironment = (environment: ServiceEnvironment) => {
  localStorage.setItem(serviceEnvironmentKey, JSON.stringify(environment));
};

export const getServiceEnvironment = (): ServiceEnvironment => {
  if (window.location.pathname.includes("overview")) {
    return ServiceEnvironment.Overview;
  }

  if (window.location.pathname.includes("referrals")) {
    return ServiceEnvironment.Referrals;
  }

  if (window.location.pathname.includes("impact")) {
    return ServiceEnvironment.Impact;
  }

  const persistedEnvironment = localStorage.getItem(serviceEnvironmentKey);
  if (persistedEnvironment !== null && persistedEnvironment !== "null") {
    return JSON.parse(persistedEnvironment);
  }

  if (getUrlParameter("env") !== null) {
    return parseInt(getUrlParameter("env")) as ServiceEnvironment;
  }

  return null;
};

export const getReportSelectionPath = (): string => {
  const environment = getServiceEnvironment();
  if (environment === null) {
    return "/";
  }

  switch (environment) {
    case ServiceEnvironment.Overview:
      return "/overview";
    case ServiceEnvironment.Referrals:
      return "/referrals";
    default:
      return "/impact";
  }
};
