import { ValidationSummary } from "../types/ValidationSummary";

export const validateInput = (item: any): ValidationSummary => {
  let validationSummary = [];
  if (
    item.variant === null ||
    item.variant === "" ||
    item.variant === undefined
  ) {
    return {
      errors: ["ERROR_NO_VARIANT_SELECTED"],
      isValid: false
    };
  }

  if (item.variant.name === "SEARCH_TERMS_PER_TIME") {
    validateDate(item, "ERROR_NO_DATE_SELECTED", validationSummary);
  }

  return {
    errors: validationSummary,
    isValid: validationSummary.length === 0
  };
};

const validateDate = (
  item: any,
  error: string,
  validationSummary: string[]
) => {
  if (
    item.from === undefined ||
    item.to === undefined ||
    item.from === "" ||
    item.to === ""
  ) {
    validationSummary.push(error);
  }
};
