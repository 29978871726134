import { ItemType } from "../../entity/item/ItemType";

export const setDateRangeOfItem = (state, action) => {
    return {
        ...state,
        metrics: state.metrics.map(
            (metric) => metric.id === action.item.id ? 
                                        {
                                            ...metric,
                                            from: action.range[0],
                                            to: action.range[1]
                                        }
                                    : metric
        ),
        sections: state.sections.map(
            section => section.id === action.item.reportSectionId ?
            {
                ...section,
                charts: section.charts.map(
                    (chart) => chart.id === action.item.id ? 
                                                {
                                                    ...chart,
                                                    from: action.range[0],
                                                    to: action.range[1]
                                                }
                                            : chart
                )
            }
            : section
        )
    }
}

export const dropSelectorMappingsOfItem = (state, action) => {
    return {
        ...state,
        metrics: state.metrics.map(
            (metric) => metric.id === action.item.id ? 
                                        {
                                            ...metric,
                                            mappings: [],
                                            data: null,
                                            from: null,
                                            to: null
                                        }
                                    : metric
        ),
        sections: state.sections.map(
            section => section.id === action.item.reportSectionId ?
            {
                ...section,
                charts: section.charts.map(
                    (chart) => chart.id === action.item.id ? 
                                                {
                                                    ...chart,
                                                    mappings: [],
                                                    data: null,
                                                    from: null,
                                                    to: null
                                                }
                                            : chart
                )
            }
            : section
        )
    }
}

export const addSelectorMappingToItem = (state, action) => {
    return {
        ...state,
        sections: state.sections.map(
            section => section.id === action.item.reportSectionId ?
                        {
                            ...section,
                            charts: section.charts.map(
                                chart => chart.id === action.item.id ? 
                                    {
                                        ...chart,
                                        mappings: chart.mappings.find(m => m.value === action.mapping.value) !== undefined
                                            ? chart.mappings 
                                            : [
                                                ...chart.mappings, {
                                                    columnName: action.mapping.columnName,
                                                    id: action.mapping.id,
                                                    value: action.mapping.value,
                                                    columnNameDisplay: action.mapping.label
                                                }
                                            ]
                                    }
                                    : chart
                            )
                        }
                        : section
        )
    }
}

export const switchSelectorMappingOfItem = (state, action) => {
    let mapping = {
        columnName: action.mapping.columnName,
        id: action.mapping.id,
        value: action.mapping.value,
        columnNameDisplay: action.mapping.label
    };

    return {
        ...state,
        metrics: state.metrics.map(
            (metric) => metric.id === action.item.id ? 
                                        {
                                            ...metric,
                                            mappings: [mapping]
                                        }
                                    : metric
        ),
        sections: state.sections.map(
            section => section.id === action.item.reportSectionId ?
            {
                ...section,
                charts: section.charts.map(
                    (chart) => chart.id === action.item.id ? 
                                                {
                                                    ...chart,
                                                    mappings: [mapping]
                                                }
                                            : chart
                )
            }
            : section
        )
    }
}

export const deleteSelectorMappingFromItem = (state, action) => {
    return {
        ...state,
        metrics: state.metrics.map(
            (metric) => metric.id === action.item.id ? 
                                        {
                                            ...metric,
                                            mappings: metric.mappings.filter(mapping => mapping.value !== action.mapping.value)
                                        }
                                    : metric
        ),
        sections: state.sections.map(
            section => section.id === action.item.reportSectionId ?
            {
                ...section,
                charts: section.charts.map(
                    (chart) => chart.id === action.item.id ? 
                                                {
                                                    ...chart,
                                                    mappings: chart.mappings.filter(mapping => mapping.value !== action.mapping.value)
                                                }
                                            : chart
                )
            }
            : section
        )
    }
}

export const addItemTarget = (state, action) => {
    return {
        ...state,
        metrics: state.metrics.map(
            (metric) => metric.id === action.item.id ?  
                                        {
                                            ...metric,
                                            values: [action.target]
                                        }
                                    : metric
        ),
        sections: state.sections.map(
            section => section.id === action.item.reportSectionId ?
            {
                ...section,
                charts: section.charts.map(
                    (chart) => chart.id === action.item.id ?  
                                                {
                                                    ...chart,
                                                    values: [action.target]
                                                }
                                            : chart
                )
            }
            : section
        )
    }
}

export const addItemFilter = (state, action) => {
    return { 
        ...state, 
        metrics: state.metrics.map(
                (metric) => metric.id === action.item.id ? updateOrInsertFilter(metric, action.filter, action.filterValue) : metric
        ),
        sections: state.sections.map(
            section => section.id === action.item.reportSectionId ?
            {
                ...section,
                charts: section.charts.map(
                    (chart) => chart.id === action.item.id ? updateOrInsertFilter(chart, action.filter, action.filterValue) : chart
                )
            }
            : section
        )
        }
}

const updateOrInsertFilter = (item, filter, filterValue) => {
    if (item.filters.find(f => f.columnName === filter.name) !== undefined) {
        return { 
            ...item, 
            filters: item.filters.map(
                (f) => f.columnName === filter.name ? {...f, filterName: filterValue.value, filterNameDisplay: filterValue.display }
                                        : f
            )
         }
    }

    return {
        ...item,
        filters: [
            ...item.filters, {
                    columnName: filter.name,
                    columnNameDisplay: filter.nameDisplay,
                    filterName: filterValue.value,
                    filterNameDisplay: filterValue.display
            }
        ]
    }
}

export const setItemValidationErrors = (state, action) => {
    return { 
        ...state, 
        metrics: state.metrics.map(
            (metric) => metric.id === action.item.id ? 
                                        {
                                            ...metric,
                                            errors: action.errors
                                        }
                                    : metric
        ),
        sections: state.sections.map(
            (section) => section.id === action.item.reportSectionId ? 
                                        {
                                            ...section,
                                            charts: section.charts.map(
                                                (chart) => chart.id === action.item.id ? 
                                                                            {
                                                                                ...chart,
                                                                                errors: action.errors
                                                                            }
                                                                        : chart
                                            )
                                        }
                                    : section
        )
    }
}

export const startLoadItemData = (state, action) => {
    return {
        ...state,
        metrics: state.metrics.map(
            (metric) => metric.id === action.item.id ? 
                                        {
                                            ...metric,
                                            isLoading: true,
                                        }
                                    : metric
        ),
        sections: state.sections.map(
            (section) => section.id === action.item.reportSectionId ? 
                                        {
                                            ...section,
                                            charts: section.charts.map(
                                                (chart) => chart.id === action.item.id ? 
                                                                            {
                                                                                ...chart,
                                                                                isLoading: true
                                                                            }
                                                                        : chart
                                            )
                                        }
                                    : section
        )
    }
}

export const setItemData = (state, action) => {
    return { 
        ...state,
        metrics: state.metrics.map(
            (metric) => metric.id === action.item.id ? 
                                        {
                                            ...metric,
                                            value: action.data.entries[0].payload[0].value + action.data.entries[0].payload[0].unit,
                                            isLoading: false,
                                            errors: []
                                        }
                                    : metric
        ),
        sections: state.sections.map(
            (section) => section.id === action.item.reportSectionId ? 
                                        {
                                            ...section,
                                            charts: section.charts.map(
                                                (chart) => chart.id === action.item.id
                                                    ? {
                                                        ...chart,
                                                        data: action.data,
                                                        isLoading: false,
                                                        errors: []
                                                    }
                                                    : chart
                                            )
                                        }
                                    : section
        )
     }
}

export const addItemValue = (state, action) => {
    return {
        ...state,
        sections: state.sections.map(
            (section) => section.id === action.item.reportSectionId ? 
                                        {
                                            ...section,
                                            charts: section.charts.map(
                                                (chart) => chart.id === action.item.id ? updateChartValue(chart, action.value) : chart
                                            )
                                        }
                                    : section
        )
    }
}

export const addItemSourceVariant = (state, action) => {
    return {
        ...state,
        metrics: state.metrics.map(
            (metric) => metric.id === action.item.id ? 
                                        {
                                            ...metric,
                                            variant: action.variant
                                        }
                                    : metric
        ),
        sections: state.sections.map(
            (section) => section.id === action.item.reportSectionId ? 
                                        {
                                            ...section,
                                            charts: section.charts.map(
                                                (chart) => chart.id === action.item.id ? {...chart, variant: action.variant } : chart
                                            )
                                        }
                                    : section
        )
    }
}

export const removeItemSourceVariant = (state, action) => {
    return {
        ...state,
        metrics: state.metrics.map(
            (metric) => metric.id === action.item.id ? 
                                        {
                                            ...metric,
                                            variant: ""
                                        }
                                    : metric
        ),
        sections: state.sections.map(
            (section) => section.id === action.item.reportSectionId ? 
                                        {
                                            ...section,
                                            charts: section.charts.map(
                                                (chart) => chart.id === action.item.id ? {...chart, variant: "" } : chart
                                            )
                                        }
                                    : section
        )
    }
}

const updateChartValue = (chart, value) => {
    if (chart.type === ItemType.Scatter) {
        if (chart.values.find(v => v.value === value.value) !== undefined) {
            chart = {
                ...chart,
                values: chart.values.filter(v => v.value !== value.value)
            }
        } 
    }
    
    return {
        ...chart,
        values: [...chart.values, value]
    }
}

export const deleteItemValue = (state, action) => {
    return {
        ...state,
        sections: state.sections.map(
            (section) => section.id === action.item.reportSectionId ? 
                                        {
                                            ...section,
                                            charts: section.charts.map(
                                                (chart) => chart.id === action.item.id ? 
                                                                            {
                                                                                ...chart,
                                                                                values: chart.values.filter(v => v.columnName !== action.value.columnName)
                                                                            }
                                                                        : chart
                                            )
                                        }
                                    : section
        )
    }
}