import { CLOSE_FILTER_DRAWER, OPEN_FILTER_DRAWER, SELECT_ITEM } from "../actions/filterActions";

const defaultState = {
    drawer: {
        open: false,
        context: -1
    },
    selectedItemId: ""
}

const filterReducer = (state = defaultState, action)=> {
    switch (action.type) {
        case SELECT_ITEM:
            return {
                ...state,
                selectedItemId: action.itemId
            }
        case OPEN_FILTER_DRAWER:
            return {
                ...state, 
                drawer: {
                    ...state.drawer,
                    open: true,
                    context: action.context
                }     
            }
        case CLOSE_FILTER_DRAWER:
            return {
                ...state, 
                drawer: {
                    ...state.drawer,
                    open: false
                }     
            }
        default:
            return state;
    }
}

export default filterReducer;