import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import rootReducer from "./reducers/rootReducer";
import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import thunkMiddleware from "redux-thunk";

import "@fontsource/poppins/300.css";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/500.css";
import "@fontsource/poppins/600.css";

import "./styles/styles.css";

import { Entrypoint, LoadingBackdrop } from "pact.drural.app-skeleton";
import { DruralThemeProvider } from "pact.drural.ui";
import { SideBarMenuItemCollection } from "./components/navigation/SideBarMenuItemCollection";

const AppSkeleton = lazy(() =>
  import("pact.drural.app-skeleton").then(module => {
    return { default: module.AppSkeleton };
  })
);
const AuthenticatedAppSkeleton = lazy(() =>
  import("pact.drural.app-skeleton").then(module => {
    return { default: module.AuthenticatedAppSkeleton };
  })
);

const ReportSelection = lazy(() => import("./components/ReportSelection"));
const ComplexServiceProviderSelection = lazy(() => import("./components/ComplexServiceProviderSelection"));
const ServiceReportEditor = lazy(() =>
  import("./components/ServiceReportEditor")
);
const ServiceSelection = lazy(() => import("./components/ServiceSelection"));
const ServiceReport = lazy(() => import("./components/ServiceReport"));
const TemplateSelection = lazy(() => import("./components/TemplateSelection"));

const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;

const enhancer = composeEnhancers(applyMiddleware(thunkMiddleware));

const store = createStore(rootReducer, enhancer);

// <AuthenticatedAppSkeleton menuItems={SideBarMenuItemCollection}>

ReactDOM.render(
  <Provider store={store}>
    <DruralThemeProvider>
      <Router>
        <Suspense fallback={<LoadingBackdrop />}>
          <Routes>
            <Route exact path="/entry" element={<Entrypoint />} />
            <Route
              exact
              path="/"
              element={
                <AppSkeleton menuItems={SideBarMenuItemCollection}>
                  <ComplexServiceProviderSelection />
                </AppSkeleton>
              }
            />
            <Route
              exact
              path="/overview"
              element={
                <AppSkeleton menuItems={SideBarMenuItemCollection}>
                  <ReportSelection />
                </AppSkeleton>
              }
            />
            <Route
              exact
              path="/referrals"
              element={
                <AppSkeleton menuItems={SideBarMenuItemCollection}>
                  <ReportSelection />
                </AppSkeleton>
              }
            />
            <Route
              exact
              path="/impact"
              element={
                <AppSkeleton menuItems={SideBarMenuItemCollection}>
                  <ReportSelection />
                </AppSkeleton>
              }
            />
            <Route
              exact
              path="/service-selection"
              element={
                <AuthenticatedAppSkeleton menuItems={SideBarMenuItemCollection}>
                  <ServiceSelection />
                </AuthenticatedAppSkeleton>
              }
            />
            <Route
              exact
              path="/template-selection"
              element={
                <AuthenticatedAppSkeleton menuItems={SideBarMenuItemCollection}>
                  <TemplateSelection />
                </AuthenticatedAppSkeleton>
              }
            />
            <Route
              exact
              path="/report"
              element={
                <AppSkeleton menuItems={SideBarMenuItemCollection}>
                  <ServiceReport />
                </AppSkeleton>
              }
            />
            <Route
              exact
              path="/edit"
              element={
                <AuthenticatedAppSkeleton menuItems={SideBarMenuItemCollection}>
                  <ServiceReportEditor />
                </AuthenticatedAppSkeleton>
              }
            />
          </Routes>
        </Suspense>
      </Router>
    </DruralThemeProvider>
  </Provider>,
  document.getElementById("root")
);
