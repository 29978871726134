import { DataSourceFilterType } from "../../entity/DataSourceFilterType";
import { DataSourceMapping } from "../../entity/DataSourceMapping";
import { ValidationSummary } from "../types/ValidationSummary";

export const validateInput = (item: any): ValidationSummary => {
    let validationSummary = [];

    if (item.variant === null || item.variant === "" || item.variant === undefined) {
        return {
            errors: ["ERROR_NO_VARIANT_SELECTED"],
            isValid: false
        }
    }

    switch(item.variant.name){
        case "REFERRALS_TO_SERVICE":
            validateProperty(DataSourceFilterType.Service, item.mappings, "ERROR_NO_SERVICE_SELECTED", validationSummary);
            validateProperty(DataSourceFilterType.ServiceCategory, item.mappings, "ERROR_NO_CATEGORY_SELECTED", validationSummary);
            validateDate(item, "ERROR_NO_DATE_SELECTED", validationSummary);
            break;
        case "REFERRALS_TO_CATEGORY":
            validateProperty(DataSourceFilterType.ServiceCategory, item.mappings, "ERROR_NO_CATEGORY_SELECTED", validationSummary);
            validateDate(item, "ERROR_NO_DATE_SELECTED", validationSummary);
            break;
        case "REFERRALS_BY_STATUS":
            validateProperty(DataSourceFilterType.ReferralStatus, item.mappings, "ERROR_NO_STATUS_SELECTED", validationSummary);
            validateDate(item, "ERROR_NO_DATE_SELECTED", validationSummary);
            break;
        case "REFERRALS_BY_DATE":
            validateDate(item, "ERROR_NO_DATE_SELECTED", validationSummary);
            validateProperty(DataSourceFilterType.DateGroup, item.mappings, "ERROR_NO_DATE_GROUP_SELECTED", validationSummary)
            break;
    }

    return {
        errors: validationSummary,
        isValid: validationSummary.length === 0
    };
}

const validateProperty = (name: string, mappings: DataSourceMapping[], error: string, validationSummary: string[]) => {
    if (mappings.find(m => m.columnName === name) === undefined) {
        validationSummary.push(error)
    }
}

const validateDate = (item: any, error: string, validationSummary: string[]) => {
    if (item.from === undefined || item.to === undefined || item.from === ""  || item.to === "") {
        validationSummary.push(error)
    }
}