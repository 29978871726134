export const deleteChart = (state, action) => {
    let modifiedState = {
        ...state,
        sections: state.sections.map(
            (section) => section.id === action.chart.reportSectionId 
                            ? {
                                ...section,
                                charts: section.charts.filter(c => c.id !== action.chart.id)
                            }
                            : section
        )
    }

    // remove empty sections
    modifiedState.sections = modifiedState.sections.filter(section => section.description !== "" || section.charts.length > 0);

    return modifiedState;
}

export const moveChart = (state, action) => {
    let targetChartSectionId = action.targetChart.reportSectionId;
    let movedChartSectionId = action.movedChart.reportSectionId;

    // swap report sections if neccessary
    if (action.movedChart.reportSectionId === action.targetChart.reportSectionId) {
        let sectionIndex = state.sections.findIndex(s =>  s.id  === targetChartSectionId);
        let previousChartIndex = state.sections[sectionIndex].charts.findIndex(c => c.id === action.targetChart.id);
        let movedChartIndex  = state.sections[sectionIndex].charts.findIndex(c => c.id === action.movedChart.id);

        return {
            ...state,
            sections: state.sections.map(
                (section) => section.id === targetChartSectionId
                                ? {
                                    ...section,
                                    charts: swapChartsInSameSection(section.charts, previousChartIndex, movedChartIndex)
                                }
                                : section
            )
        }


    } else {
        let targetSectionId = action.movedChart.reportSectionId;

        action.movedChart.reportSectionId = action.targetChart.reportSectionId;
        action.targetChart.reportSectionId = targetSectionId;
    }

    return { 
        ...state,
        sections: state.sections.map(
            (section) => section.id === targetChartSectionId ? 
                                    {
                                        ...section,
                                        charts: swapChartsInDifferentSection(section, action.targetChart, action.movedChart)
                                    }
                                    : section.id === movedChartSectionId ?
                                        {
                                            ...section,
                                            charts: swapChartsInDifferentSection(section, action.movedChart, action.targetChart)
                                        }
                                        : section
        )
    }
}

const swapChartsInDifferentSection = (section, sectionTargetChart, sectionPreviousChart) => {
    return section.charts.map(
        chart => chart.id === sectionPreviousChart.id 
            ? sectionTargetChart
            : chart.id === sectionTargetChart.id 
                ? sectionPreviousChart
                :chart
        )
}

const swapChartsInSameSection = (charts, previousChartIndex, movedChartIndex) => {
    const swappedCharts = [...charts];
    [swappedCharts[previousChartIndex], swappedCharts[movedChartIndex]] = [swappedCharts[movedChartIndex], swappedCharts[previousChartIndex]]

    return swappedCharts;
}

export const changeChartTitle = (state, action) => {
    return { 
        ...state,
        sections: state.sections.map(
            (section) => section.id === action.chart.reportSectionId ? 
                                        {
                                            ...section,
                                            charts: section.charts.map(
                                                (chart) => chart.id === action.chart.id ? 
                                                                            {
                                                                                ...chart,
                                                                                title: action.title
                                                                            }
                                                                        : chart
                                            )
                                        }
                                    : section
        )
    }
}

export const addSectionDescription = (state, action) => {
    return {
        ...state,
        sections: state.sections.map(
            section => section.id === action.sectionId ?
                                    {
                                        ...section,
                                        description: "<div></div>"
                                    }
                                    : section
        )
    }
}

export const changeSectionDescription = (state, action) => {
    return {
        ...state,
        sections: state.sections.map(
            section => section.id === action.sectionId ?
                                    {
                                        ...section,
                                        description: action.description
                                    }
                                    : section
        )
    }
}

export const deleteSectionDescription = (state, action) => {
    let modifiedState = {
        ...state,
        sections: state.sections.map(
            section => section.id === action.sectionId ?
                                    {
                                        ...section,
                                        description: ""
                                    }
                                    : section
        )
    }

    modifiedState.sections = modifiedState.sections.filter(section => section.description !== "" || section.charts.length > 0);

    return modifiedState;
}

export const createChart = (state, action) => {
    if (action.section === undefined) {
        let sectionId = Math.floor(Math.random() * 10000000) + "";
        return {
            ...state,
            sections: [
                ...state.sections,
                {
                    id: sectionId,
                    reportId: state.id,
                    description: "",
                    position: state.sections.length,
                    charts: [
                        {
                            position: 0,
                            type: action.chart.type,
                            reportSectionId: sectionId,
                            dataSourceId: action.chart.dataSourceId,
                            dataSetName: action.chart.dataSourceName,
                            id: Math.floor(Math.random() * 10000000) + "",
                            isLoading: false,
                            values: [],
                            mappings: [],
                            filters: [],
                            errors: [],
                            from: null,
                            to: null
                        }
                    ]
                }
            ]
        }
    }

    return {
        ...state,
        sections: state.sections.map(
            section => section.id === action.section.id ?
                                    {
                                        ...section,
                                        charts: [
                                            ...section.charts, 
                                            {
                                                type: action.chart.type,
                                                reportSectionId: action.section.id,
                                                dataSourceId: action.chart.dataSourceId,
                                                dataSetName: action.chart.dataSourceName,
                                                id: Math.floor(Math.random() * 10000000) + "",
                                                isLoading: false,
                                                values: [],
                                                mappings: [],
                                                filters: [],
                                                errors: [],
                                                from: null,
                                                to: null
                                            }
                                        ]
                                    }
                                    : section
        )
    }
}