export const OPEN_FILTER_DRAWER = "OPEN_FILTER_DRAWER"
export const CLOSE_FILTER_DRAWER = "CLOSE_FILTER_DRAWER"

export const SELECT_ITEM = "SELECT_ITEM"

export const ADD_SELECTOR_MAPPING_TO_ITEM = "ADD_SELECTOR_MAPPING_TO_ITEM" 
export const DELETE_SELECTOR_MAPPING_FROM_ITEM = "DELETE_SELECTOR_MAPPING_FROM_ITEM"
export const SWITCH_SELECTOR_MAPPING_OF_ITEM = "SWITCH_SELECTOR_MAPPING_OF_ITEM"
export const DROP_SELECTOR_MAPPINGS_OF_ITEM = "DROP_SELECTOR_MAPPINGS_OF_ITEM"

export const ADD_ITEM_TARGET = "ADD_ITEM_TARGET"
export const ADD_ITEM_VALUE = "ADD_ITEM_VALUE"
export const DELETE_ITEM_VALUE = "DELETE_ITEM_VALUE"

export const SET_ITEM_DATE_RANGE = "SET_ITEM_DATE_RANGE"

export const ADD_ITEM_FILTER = "ADD_ITEM_FILTER"
export const ADD_ITEM_SOURCE_VARIANT = "ADD_ITEM_SOURCE_VARIANT"
export const REMOVE_ITEM_SOURCE_VARIANT = "REMOVE_ITEM_SOURCE_VARIANT"

export const setDateRangeOfItem = (item, range) => ({
    type: SET_ITEM_DATE_RANGE,
    item,
    range
})

export const removeItemSourceVariant = (item) => ({
    type: REMOVE_ITEM_SOURCE_VARIANT,
    item
})

export const addItemSourceVariant = (item, variant) => ({
    type: ADD_ITEM_SOURCE_VARIANT,
    item,
    variant
})

export const addItemFilter = (item, filter, filterValue) => ({
    type: ADD_ITEM_FILTER,
    item,
    filter,
    filterValue
})

export const addItemValue = (item, value) => ({
    type: ADD_ITEM_VALUE,
    item,
    value
})

export const deleteItemValue = (item, value) => ({
    type: DELETE_ITEM_VALUE,
    item,
    value
})

export const addItemTarget = (item, target) => ({
    type: ADD_ITEM_TARGET,
    item,
    target
})

export const dropSelectorMappingsOfItem = (item) => ({
    type: DROP_SELECTOR_MAPPINGS_OF_ITEM,
    item
})

export const addSelectorMappingToItem = (item, mapping) => ({
    type: ADD_SELECTOR_MAPPING_TO_ITEM,
    item,
    mapping
})

export const deleteSelectorMappingFromItem = (item, mapping) => ({
    type: DELETE_SELECTOR_MAPPING_FROM_ITEM,
    item,
    mapping
})

export const switchSelectorMappingOfItem = (item, mapping) => ({
    type: SWITCH_SELECTOR_MAPPING_OF_ITEM,
    item,
    mapping
})

export const openFilterDrawer = () => ({
    type: OPEN_FILTER_DRAWER
})

export const closeFilterDrawer = () =>({
    type: CLOSE_FILTER_DRAWER
})

export const selectItem = (itemId) => ({
    type: SELECT_ITEM,
    itemId,
})